#navigation {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 99;

    #navigation-container {
        margin: 0rem 1.5rem;
        display: flex;
        padding: 0.5rem 0rem;

        #nav-logo {
            flex-grow: 1;
            width: 0rem;
            display: flex;
            align-items: center;

            #nav-logo-button {
                text-decoration: none;

                h1 {
                    font-family: 'Inter';
                    font-size: 1.8rem;
                    font-weight: 800;

                    &:hover {
                        cursor: pointer;
                    }

                    #nav-logo-nova {
                        font-family: 'Inter';

                    }
                }
            }
        }

        #nav-options {
            flex-grow: 1;
            width: 0rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            ul {
                display: flex;
                list-style: none;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .nav-option-button {
                        margin: 0rem 1rem;
                        padding: 0.5rem 1.5rem;
                        text-decoration: none;
                        transition: 0.2s;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

        }

        #mobile-nav-options {
            flex-grow: 0;
            width: 0rem;
            overflow: hidden;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            #mobile-menu-button {
                padding: 0.3rem;
                width: 1rem;
                height: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                border-radius: 1rem;
                transition: 0.5s;

                &:hover {
                    cursor: pointer;
                    background-color: #ffffff50;
                }

                .mobile-menu-bar {
                    height: 1px;
                    width: 100%;
                    background-color: white;
                    border-radius: 1px;
                }
            }

            #mobile-menu-container {
                position: absolute;
                border-radius: 1rem;

                #mobile-menu {
                    padding: 0.5rem;
                    border-radius: 0.5rem;

                    ul {

                        li {
                            list-style: none;
                            display: flex;
                            justify-content: center;

                            a {
                                padding: 0.5rem 1rem;
                                width: 100%;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .mobile-menu-active {
                opacity: 0;
                visibility: hidden;
                transform: translate(0, 6.5rem);
                transition: 0.5s;
            }

            .mobile-menu-inactive {
                opacity: 0;
                visibility: hidden;
                transform: translate(0, 5.5rem);
                transition: 0.5s;
            }
        }

        .nav-theme-button {
            padding: 0.5rem;
            display: flex;
            border-radius: 50%;
            transition: 0.5s;

            &:hover {
                cursor: pointer;
            }

            .theme-icon {
                font-size: 1.2rem;
            }
        }
    }

    @media (max-width: $tv-width) {

        #navigation-container {

            #nav-logo {

                #nav-logo-button {

                    h1 {
                        font-size: 1.8rem;
                    }
                }
            }

            #nav-options {

                ul {

                    li {
                        .nav-option-button {}
                    }
                }

            }

            #mobile-nav-options {

                #mobile-menu-button {

                    .mobile-menu-bar {}
                }

                #mobile-menu-container {

                    #mobile-menu {}
                }

                .mobile-menu-active {}

                .mobile-menu-inactive {}
            }

            .nav-theme-button {

                .theme-icon {}
            }
        }
    }

    @media (max-width: $desktop-width) {
        #navigation-container {

            #nav-logo {

                #nav-logo-button {

                    h1 {
                        font-size: 1.7rem;
                    }
                }
            }

            #nav-options {

                ul {

                    li {

                        .nav-option-button {
                            margin: 0rem 0.8rem;
                            padding: 0.5rem 1.5rem;
                            font-size: 0.9rem;
                        }
                    }
                }

            }

            #mobile-nav-options {

                #mobile-menu-button {

                    .mobile-menu-bar {}
                }

                #mobile-menu-container {

                    #mobile-menu {}
                }

                .mobile-menu-active {}

                .mobile-menu-inactive {}
            }

            .nav-theme-button {
                padding: 0.4rem;

                .theme-icon {
                    font-size: 1rem;
                }
            }
        }
    }

    @media (max-width: $laptop-width) {
        #navigation-container {

            #nav-logo {

                #nav-logo-button {

                    h1 {
                        font-size: 1.5rem;
                    }
                }
            }

            #nav-options {

                ul {

                    li {

                        .nav-option-button {
                            margin: 0rem 0.5rem;
                            padding: 0.5rem 1.3rem;
                            font-size: 0.8rem;
                        }
                    }
                }

            }

            #mobile-nav-options {

                #mobile-menu-button {

                    .mobile-menu-bar {}
                }

                #mobile-menu-container {

                    #mobile-menu {}
                }

                .mobile-menu-active {}

                .mobile-menu-inactive {}
            }

            .nav-theme-button {
                padding: 0.3rem;

                .theme-icon {
                    font-size: 0.8rem;
                }
            }
        }
    }

    @media (max-width: $tablet-width) {

        #navigation-container {

            #nav-logo {

                #nav-logo-button {

                    h1 {
                        font-size: 1.3rem;
                    }
                }
            }

            #nav-options {
                flex-grow: 0;
                overflow: hidden;

                ul {

                    li {
                        .nav-option-button {}
                    }
                }

            }

            #mobile-nav-options {
                flex-grow: 1;
                overflow: auto;

                #mobile-menu-button {

                    .mobile-menu-bar {}
                }

                #mobile-menu-container {

                    #mobile-menu {}
                }

                .mobile-menu-active {
                    opacity: 1;
                    visibility: visible;
                }

                .mobile-menu-inactive {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            .nav-theme-button {
                padding: 0.5rem;
                width: 100%;
                justify-content: center;
                border-radius: 0.3rem;

                .theme-icon {
                    font-size: 1rem;
                }
            }
        }
    }

    @media (max-width: $mobile-width) {
        #navigation-container {

            #nav-logo {

                #nav-logo-button {

                    h1 {
                        font-size: 1.1rem;
                    }
                }
            }

            #nav-options {

                ul {

                    li {
                        .nav-option-button {}
                    }
                }

            }

            #mobile-nav-options {

                #mobile-menu-button {

                    .mobile-menu-bar {}
                }

                #mobile-menu-container {

                    #mobile-menu {}
                }

                .mobile-menu-active {}

                .mobile-menu-inactive {}
            }

            .nav-theme-button {

                .theme-icon {}
            }
        }
    }
}