#exercises {
    flex-grow: 1;

    .page-title {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 3rem;
        font-family: 'Inter';

        a {
            white-space: nowrap;
            font-family: inherit;
            text-decoration: none;
            color: inherit;
            transition: 0.2s;

            &:hover {
                opacity: 0.2;
            }
        }
    }

    #exercise-content {
        margin: 3rem 0rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        #exercise-content-prompt {
            text-align: center;
        }

        #exercise-categories {
            display: flex;
            justify-content: space-evenly;
            width: 100%;

            .exercise-category {
                box-sizing: border-box;
                margin: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30rem;
                height: 30rem;
                font-size: 2rem;
                border-radius: 1rem;
                transition: 0.5s;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }

                &:hover {
                    cursor: pointer;
                    padding: 1rem;
                    font-size: 2.5rem;

                    span {
                        border: 2px solid white;
                    }
                }

            }
        }

        #category-exercises {
            width: 100%;
            height: 20rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .category-exercise-button {
                margin: 0rem 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 15rem;
                height: 15rem;
                font-size: 1.5rem;
                text-align: center;
                border-radius: 0.5rem;
                transition: 0.5s;

                &:hover {
                    cursor: pointer;
                    margin: 0rem;
                    width: 18rem;
                    height: 18rem;
                }

                .exercise-name {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    @media (max-width: $tv-width) {

        .page-title {}

        #exercise-content {

            #exercise-content-prompt {}

            #exercise-categories {

                .exercise-category {

                    &:hover {

                        span {}
                    }

                }
            }

            #category-exercises {

                .category-exercise-button {

                    &:hover {}

                    .exercise-name {}
                }
            }
        }
    }

    @media (max-width: $desktop-width) {

        .page-title {}

        #exercise-content {

            #exercise-content-prompt {}

            #exercise-categories {

                .exercise-category {
                    width: 25rem;
                    height: 25rem;
                    font-size: 1.5rem;

                    &:hover {
                        padding: 0.8rem;
                        font-size: 2rem;

                        span {}
                    }

                }
            }

            #category-exercises {

                .category-exercise-button {

                    &:hover {}

                    .exercise-name {}
                }
            }
        }
    }

    @media (max-width: $laptop-width) {

        .page-title {}

        #exercise-content {

            #exercise-content-prompt {}

            #exercise-categories {

                .exercise-category {
                    width: 20rem;
                    height: 20rem;
                    font-size: 1.2rem;

                    &:hover {
                        width: 20rem;
                        height: 20rem;
                        font-size: 1.6rem;

                        span {}
                    }

                }
            }

            #category-exercises {

                .category-exercise-button {
                    width: 10rem;
                    height: 10rem;
                    font-size: 1rem;

                    &:hover {
                        width: 13rem;
                        height: 13rem;
                    }

                    .exercise-name {}
                }
            }
        }
    }

    @media (max-width: $tablet-width) {
        .page-title {
            font-size: 2.5rem;
        }

        #exercise-content {

            #exercise-content-prompt {}

            #exercise-categories {
                flex-direction: column;
                align-items: center;

                .exercise-category {
                    width: 20rem;
                    height: 15rem;
                    font-size: 1.2rem;

                    &:hover {
                        width: 20rem;
                        height: 15rem;
                        font-size: 1.5rem;

                        span {}
                    }

                }
            }

            #category-exercises {
                flex-direction: column;
                height: 100%;

                .category-exercise-button {
                    margin: 1rem 0rem;
                    width: 15rem;
                    height: 8rem;

                    &:hover {
                        margin: 0rem;
                        width: 15rem;
                        height: 10rem;
                    }

                    .exercise-name {}
                }
            }
        }
    }

    @media (max-width: $mobile-width) {
        .page-title {}

        #exercise-content {

            #exercise-content-prompt {}

            #exercise-categories {

                .exercise-category {
                    width: 20rem;
                    height: 10rem;
                    font-size: 1rem;

                    &:hover {
                        width: 20rem;
                        height: 10rem;
                        font-size: 1.2rem;

                        span {}
                    }

                }
            }

            #category-exercises {

                .category-exercise-button {

                    &:hover {}

                    .exercise-name {}
                }
            }
        }
    }
}