#footer {
    width: 100%;

    #footer-bottom {
        padding: 1rem 0rem;
        font-size: 1rem;
        text-align: center;
    }

    @media (max-width: $tv-width) {
        #footer-bottom {
            font-size: 0.95rem;
        }
    }

    @media (max-width: $desktop-width) {
        #footer-bottom {
            font-size: 0.9rem;
        }
    }

    @media (max-width: $laptop-width) {
        #footer-bottom {
            font-size: 0.85rem;
        }
    }

    @media (max-width: $tablet-width) {
        #footer-bottom {
            font-size: 0.8rem;
        }
    }

    @media (max-width: $mobile-width) {
        #footer-bottom {
            font-size: 0.75rem;
        }
    }
}