#exercise-content {
    -webkit-user-select: none;

    #interval-range-selection-window {
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3rem;

        #interval-range-controls {
            display: flex;
            align-items: center;

            #interval-range-display {
                display: flex;
                justify-content: center;
                width: 10rem;
                aspect-ratio: 1/1;
                font-size: 2em;
                animation-duration: 0.3s;
                animation-name: slideDown;

                @keyframes slideDown {
                    0% {
                        transform: translateY(-1rem);
                    }

                    100% {
                        transform: translateY(0);
                    }
                }
            }

            .interval-range-control-button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5em;
                height: 5rem;
                aspect-ratio: 1/1;
                border-radius: 0.5rem;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        #interval-range-unit {
            margin-bottom: 3rem;
            font-size: 0.6em;
        }

        #interval-range-start-button {
            font-family: 'Inter';
            font-size: 0.8em;
            border-width: 0.2rem;
            border-style: solid;
            border-radius: 0.5rem;
            padding: 1rem;
            transition: 0.3s;

            &:hover {
                cursor: pointer;
            }
        }
    }

    #interval-training-window {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        #note-interval-window {
            margin: 2rem 0rem;
            display: grid;
            grid-template: 8rem / repeat(2, 12rem);
            gap: 5rem;

            .play-note-button {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 0.8rem;
                border-style: solid;
                border-width: 0.2rem;

                span {
                    z-index: 2;
                }

                &:hover {
                    cursor: pointer;
                }

                .play-note-button-title {
                    font-family: 'Inter';
                }

                .play-note-button-value {

                    span {
                        font-family: 'Inter';
                    }
                }

                .play-note-button-overlay {
                    position: absolute;
                    font-size: 8em;
                    transform: rotate(270deg);
                }
            }
        }

        #half-step-window {
            font-weight: bold;
        }

        #note-selection-window {
            display: grid;
            grid-template: repeat(3, 6rem) / 18rem;
            gap: 1rem;
            font-size: 2rem;

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.5rem;
            }

            .note-change-button {
                margin: 1rem 3rem;
                border-width: 0.2rem;
                border-style: solid;
                transition: 0.3s;

                &:hover {
                    cursor: pointer;
                }
            }

            #note-select-button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Inter';
                font-size: 2em;
                border-width: 0.2rem;
                border-style: solid;
                animation-duration: 0.3s;
                animation-name: expand;

                &:hover {
                    cursor: pointer;
                }

                @keyframes expand {
                    0% {
                        transform: scale(0.8);
                    }

                    100% {
                        transform: scale(1);
                    }
                }
            }
        }


        #octave-window {
            font-size: 1.5rem;

            span {
                font-family: 'Inter';
            }
        }

        #interval-training-result-window {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 2rem;

            #result-data-display {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                #result-icon-badge {
                    display: flex;
                    padding: 0.5rem;
                    border-radius: 50%;
                    border-style: solid;
                    border-width: 0.5rem;

                    #result-icon {
                        padding: 1rem;
                        color: white;
                        font-size: 6em;
                        border-radius: 50%;
                        animation-duration: 0.3s;
                        animation-name: burst-out;

                        @keyframes burst-out {
                            0% {
                                transform: scale(0.2);
                            }

                            90% {
                                transform: scale(1.1);
                            }

                            100% {
                                transform: scale(1);
                            }
                        }
                    }
                }

                #detailed-results {
                    font-size: 0.6em;
                    margin: 1rem 0rem;

                    #detailed-results-button {

                        &:hover {
                            cursor: pointer;
                            font-weight: 900;
                            text-decoration: underline;
                        }
                    }

                    #detailed-results-dialog-backdrop {
                        position: fixed;
                        top: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        background-color: #00000095;
                        z-index: 3;

                        #detailed-results-dialog {
                            padding: 2rem;
                            display: grid;
                            gap: 2rem;
                            border-radius: 1rem;

                            #detailed-results-title {
                                font-size: 1.5rem;
                                text-align: center;
                                font-family: 'Inter';
                            }

                            #detailed-result-items {
                                display: grid;
                                gap: 0.5rem;

                                .detailed-result-item {
                                    display: flex;
                                    align-items: center;

                                    .play-note-result-button {
                                        margin: 0rem 1rem;
                                        padding: 0.5rem;
                                        display: flex;
                                        border-radius: 0.5rem;
                                        border-style: solid;
                                        border-width: 0.2rem;

                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }

                                    .detailed-result-note {

                                        span {
                                            font-family: 'Inter';
                                        }
                                    }
                                }
                            }

                            #detailed-results-dialog-actions {
                                display: flex;
                                justify-content: center;

                                button {
                                    padding: 0.5rem 1rem;

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #result-window-actions {

                #next-question-button {
                    padding: 1rem;
                    font-family: 'Inter';
                    border-radius: 0.5rem;
                    border-style: solid;
                    border-width: 0.2rem;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media (max-width: $tv-width) {

        #interval-range-selection-window {

            #interval-range-controls {

                #interval-range-display {}

                .interval-range-control-button {}
            }

            #interval-range-unit {}

            #interval-range-start-button {}
        }

        #interval-training-window {

            #note-interval-window {

                .play-note-button {

                    span {}

                    .play-note-button-title {}

                    .play-note-button-value {

                        span {}
                    }

                    .play-note-button-overlay {}
                }
            }

            #half-step-window {}

            #note-selection-window {

                span {}

                .note-change-button {}

                #note-select-button {}
            }


            #octave-window {

                span {}
            }

            #interval-training-result-window {

                #result-data-display {

                    #result-icon-badge {

                        #result-icon {}
                    }

                    #detailed-results {

                        #detailed-results-button {

                            &:hover {}
                        }

                        #detailed-results-dialog-backdrop {

                            #detailed-results-dialog {

                                #detailed-results-title {}

                                #detailed-result-items {

                                    .detailed-result-item {

                                        .play-note-result-button {}

                                        .detailed-result-note {

                                            span {}
                                        }
                                    }
                                }

                                #detailed-results-dialog-actions {

                                    button {}
                                }
                            }
                        }
                    }
                }

                #result-window-actions {

                    #next-question-button {}
                }
            }
        }
    }

    @media (max-width: $desktop-width) {

        #interval-range-selection-window {

            #interval-range-controls {

                #interval-range-display {}

                .interval-range-control-button {}
            }

            #interval-range-unit {}

            #interval-range-start-button {}
        }

        #interval-training-window {

            #note-interval-window {

                .play-note-button {

                    span {}

                    .play-note-button-title {}

                    .play-note-button-value {

                        span {}
                    }

                    .play-note-button-overlay {}
                }
            }

            #half-step-window {}

            #note-selection-window {

                span {}

                .note-change-button {}

                #note-select-button {}
            }


            #octave-window {

                span {}
            }

            #interval-training-result-window {

                #result-data-display {

                    #result-icon-badge {

                        #result-icon {}
                    }

                    #detailed-results {

                        #detailed-results-button {

                            &:hover {}
                        }

                        #detailed-results-dialog-backdrop {

                            #detailed-results-dialog {

                                #detailed-results-title {}

                                #detailed-result-items {

                                    .detailed-result-item {

                                        .play-note-result-button {}

                                        .detailed-result-note {

                                            span {}
                                        }
                                    }
                                }

                                #detailed-results-dialog-actions {

                                    button {}
                                }
                            }
                        }
                    }
                }

                #result-window-actions {

                    #next-question-button {}
                }
            }
        }
    }

    @media (max-width: $laptop-width) {

        #interval-range-selection-window {

            #interval-range-controls {

                #interval-range-display {}

                .interval-range-control-button {}
            }

            #interval-range-unit {}

            #interval-range-start-button {}
        }

        #interval-training-window {

            #note-interval-window {

                .play-note-button {

                    span {}

                    .play-note-button-title {}

                    .play-note-button-value {

                        span {}
                    }

                    .play-note-button-overlay {}
                }
            }

            #half-step-window {}

            #note-selection-window {

                span {}

                .note-change-button {}

                #note-select-button {}
            }


            #octave-window {

                span {}
            }

            #interval-training-result-window {

                #result-data-display {

                    #result-icon-badge {

                        #result-icon {}
                    }

                    #detailed-results {

                        #detailed-results-button {

                            &:hover {}
                        }

                        #detailed-results-dialog-backdrop {

                            #detailed-results-dialog {

                                #detailed-results-title {}

                                #detailed-result-items {

                                    .detailed-result-item {

                                        .play-note-result-button {}

                                        .detailed-result-note {

                                            span {}
                                        }
                                    }
                                }

                                #detailed-results-dialog-actions {

                                    button {}
                                }
                            }
                        }
                    }
                }

                #result-window-actions {

                    #next-question-button {}
                }
            }
        }
    }

    @media (max-width: $tablet-width) {
        #interval-range-selection-window {

            #interval-range-controls {

                #interval-range-display {}

                .interval-range-control-button {}
            }

            #interval-range-unit {}

            #interval-range-start-button {}
        }

        #interval-training-window {

            #note-interval-window {
                font-size: 0.8rem;
                grid-template: 6rem / repeat(2, 10rem);
                gap: 2rem;

                .play-note-button {

                    span {}

                    .play-note-button-title {}

                    .play-note-button-value {

                        span {}
                    }

                    .play-note-button-overlay {
                        font-size: 5em;
                    }
                }
            }

            #half-step-window {}

            #note-selection-window {

                span {}

                .note-change-button {}

                #note-select-button {}
            }


            #octave-window {

                span {}
            }

            #interval-training-result-window {

                #result-data-display {

                    #result-icon-badge {

                        #result-icon {}
                    }

                    #detailed-results {

                        #detailed-results-button {

                            &:hover {}
                        }

                        #detailed-results-dialog-backdrop {

                            #detailed-results-dialog {

                                #detailed-results-title {}

                                #detailed-result-items {

                                    .detailed-result-item {

                                        .play-note-result-button {}

                                        .detailed-result-note {

                                            span {}
                                        }
                                    }
                                }

                                #detailed-results-dialog-actions {

                                    button {}
                                }
                            }
                        }
                    }
                }

                #result-window-actions {

                    #next-question-button {}
                }
            }
        }
    }

    @media (max-width: $mobile-width) {

        #interval-range-selection-window {

            #interval-range-controls {

                #interval-range-display {}

                .interval-range-control-button {}
            }

            #interval-range-unit {}

            #interval-range-start-button {}
        }

        #interval-training-window {

            #note-interval-window {
                font-size: 0.8rem;
                grid-template: 5rem / repeat(2, 9rem);
                gap: 1.5rem;

                .play-note-button {

                    span {}

                    .play-note-button-title {}

                    .play-note-button-value {

                        span {}
                    }

                    .play-note-button-overlay {}
                }
            }

            #half-step-window {}

            #note-selection-window {

                span {}

                .note-change-button {}

                #note-select-button {}
            }


            #octave-window {

                span {}
            }

            #interval-training-result-window {

                #result-data-display {

                    #result-icon-badge {

                        #result-icon {}
                    }

                    #detailed-results {

                        #detailed-results-button {

                            &:hover {}
                        }

                        #detailed-results-dialog-backdrop {

                            #detailed-results-dialog {
                                padding: 1rem;
                                font-size: 0.8rem;

                                #detailed-results-title {}

                                #detailed-result-items {

                                    .detailed-result-item {

                                        .play-note-result-button {}

                                        .detailed-result-note {

                                            span {}
                                        }
                                    }
                                }

                                #detailed-results-dialog-actions {

                                    button {}
                                }
                            }
                        }
                    }
                }

                #result-window-actions {

                    #next-question-button {}
                }
            }
        }
    }
}