#home {
    flex-grow: 1;

    #hero-section-container {
        position: relative;
        display: flex;
        align-items: center;
        height: 100vh;
        background-image: url('/public/hero_section_image.jpg');
        background-size: cover;
        background-position: center 80%;
        background-repeat: no-repeat;

        &::before {
            position: absolute;
            width: 100%;
            height: 100%;
            backdrop-filter: brightness(25%);
            content: '';
        }

        #hero-section {
            position: relative;
            z-index: 1;

            #hero-section-title {
                text-align: center;
                font-size: 5.5rem;

                span {
                    display: block;
                }
            }

            #hero-section-info {
                margin: 3rem auto;
                display: flex;
                width: 33rem;

                span {
                    font-size: 1.5rem;
                    font-weight: 300;
                    text-align: center;
                }
            }

            #call-to-action {
                display: flex;
                justify-content: center;


                #call-to-action-button {
                    padding: 1.5rem 4rem;
                    border-width: 5px;
                    border-style: solid;
                    font-size: 1.5rem;
                    text-decoration: none;
                    transition: 0.5s;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media (max-width: $tv-width) {

        #hero-section-container {

            #hero-section {

                #hero-section-title {
                    font-size: 4.5rem;
                }

                #hero-section-info {
                    margin: 2.5rem auto;
                    width: 27rem;

                    span {
                        font-size: 1.2rem;
                    }
                }

                #call-to-action {

                    #call-to-action-button {
                        padding: 1.2rem 3.3rem;
                        border-width: 4.1px;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    @media (max-width: $desktop-width) {
        #hero-section-container {

            #hero-section {

                #hero-section-title {
                    font-size: 3.5rem;
                }

                #hero-section-info {
                    margin: 1.9rem auto;
                    width: 21rem;

                    span {
                        font-size: 1rem;
                    }
                }

                #call-to-action {

                    #call-to-action-button {
                        padding: 1rem 2.6rem;
                        border-width: 3.2px;
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    @media (max-width: $laptop-width) {
        #hero-section-container {

            #hero-section {

                #hero-section-title {
                    font-size: 3rem;
                }

                #hero-section-info {
                    margin: 1.6rem auto;
                    width: 18rem;

                    span {
                        font-size: 0.8rem;
                    }
                }

                #call-to-action {

                    #call-to-action-button {
                        padding: 0.8rem 2.2rem;
                        border-width: 2.7px;
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

    @media (max-width: $tablet-width) {
        #hero-section-container {

            #hero-section {

                #hero-section-title {
                    font-size: 2.5rem;
                }

                #hero-section-info {
                    margin: 1.2rem auto;
                    width: 14rem;

                    span {
                        font-size: 0.6rem;
                    }
                }

                #call-to-action {

                    #call-to-action-button {
                        padding: 0.6rem 1.6rem;
                        border-width: 2px;
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }

    @media (max-width: $mobile-width) {
        #hero-section-container {

            #hero-section {

                #hero-section-title {}

                #hero-section-info {

                    span {}
                }

                #call-to-action {

                    #call-to-action-button {}
                }
            }
        }
    }
}