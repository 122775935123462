#virtual-guitar-container {
    margin: 5rem 2rem;
    padding: 5rem 2rem;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    -webkit-user-select: none;

    #virtual-guitar-title {
        text-align: center;

        h2 {
            font-size: 3rem;
        }
    }

    #virtual-guitar-content {

        #virtual-guitar-control-panel {
            margin: 3rem 0rem;
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
            row-gap: 1rem;
            border-radius: 1rem;

            #virtual-guitar-main-panel {
                padding: 1rem;
                display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 1rem;
                border-width: 2px;
                border-style: solid;
                border-radius: 1rem;

                .virtual-guitar-main-panel-item {
                    display: flex;
                    flex-direction: column;

                    .main-panel-label {
                        font-size: 0.9rem;
                        font-weight: 800;
                    }

                    .main-panel-selection {
                        height: 3rem;
                        border-width: 2px;
                        border-style: solid;
                        border-radius: 0.5rem;
                        text-align: center;
                        font-size: 1rem;
                        background-color: transparent;
                        appearance: none;

                        &:hover {
                            cursor: pointer;
                            border-width: 3px;
                        }
                    }
                }
            }

            #virtual-guitar-secondary-panel {
                display: grid;
                row-gap: 1rem;

                .virtual-guitar-secondary-panel-item {
                    padding: 1rem;
                    border-width: 2px;
                    border-style: solid;
                    border-radius: 1rem;

                    .secondary-panel-label {}

                    #virtual-guitar-triads {
                        display: grid;
                        grid-template-rows: 1fr;
                        grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
                        row-gap: 1rem;
                        column-gap: 1rem;

                        p {
                            font-size: 0.9rem;
                            font-style: italic;
                        }

                        .triad-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                            height: 5rem;
                            padding: 1rem;
                            font-size: 0.8rem;
                            border-width: 2px;
                            border-style: solid;
                            border-radius: 0.5rem;

                            &:hover {
                                cursor: pointer;
                                border-width: 3px;
                            }

                            .triad-button-notes {
                                display: flex;
                                flex-direction: column;
                                text-align: center;

                                span:nth-child(1) {
                                    font-weight: 900;
                                    font-family: 'Inter';
                                }
                            }
                        }
                    }

                    #virtual-guitar-chord-constructor {

                        p {
                            font-size: 0.9rem;
                            font-style: italic;
                        }
                    }
                }

                #secondary-panel-button {
                    display: flex;
                    justify-content: center;
                    border-width: 2px;
                    border-style: solid;
                    border-radius: 1rem;
                    font-size: 2rem;

                    &:hover {
                        cursor: pointer;
                    }
                }

                #add-secondary-panel-dialog-backdrop {
                    position: fixed;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background-color: #00000095;
                    z-index: 3;

                    #add-secondary-panel-dialog {
                        padding: 2rem;
                        display: grid;
                        gap: 1rem;
                        border-radius: 1rem;

                        #add-secondary-panel-title {
                            font-family: 'Inter';
                        }

                        .add-secondary-panel-option {
                            display: flex;
                            align-items: center;

                            input {
                                width: 1rem;
                                height: 1rem;

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            label {
                                width: 100%;
                                padding: 0.2rem 0.5rem;

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }

                        #add-secondary-panel-dialog-actions {
                            display: flex;
                            justify-content: center;

                            button {
                                padding: 0.5rem 1rem;

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

        #virtual-guitar-fretboard-container {
            box-sizing: border-box;
            text-align: center;
            width: 100%;

            #virtual-guitar-neck-container {
                padding: 2rem 0rem;
                display: flex;
                flex-direction: column;
                overflow-x: scroll;

                &::-webkit-scrollbar {
                    height: 0.6rem;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 1rem;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 1rem;
                }

                #virtual-guitar-neck {
                    padding: 2rem 0rem;
                    display: flex;
                    flex-direction: column;

                    .virtual-guitar-string {
                        display: flex;
                        width: max-content;

                        &:first-child {
                            .virtual-guitar-fret {
                                border-top: 3px solid transparent;
                            }

                            span {

                                .virtual-guitar-fret {
                                    border-top: 3px solid #b8b8b8;
                                }
                            }
                        }

                        &:last-child {
                            .virtual-guitar-fret {
                                border-bottom: 3px solid transparent;
                            }

                            span {
                                .virtual-guitar-fret {
                                    border-bottom: 3px solid #b8b8b8;
                                }
                            }
                        }

                        .virtual-guitar-fret {
                            position: relative;
                            box-sizing: border-box;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 6.8rem;
                            height: 3.4rem;

                            &::after {
                                position: absolute;
                                width: 130%;
                                height: 2px;
                                background-color: #9C7C2A;
                                border-radius: 1rem;
                                content: '';
                            }

                            .virtual-guitar-note {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: black;
                                font-family: 'Inter';
                                font-size: 1rem;
                                font-weight: 800;
                                width: 2.2rem;
                                height: 2.2rem;
                                border-style: solid;
                                border-radius: 50%;
                                z-index: 1;
                                transition: 0.1s;

                                &:hover {
                                    cursor: pointer;
                                    font-size: 1.5rem;
                                    border-width: 5px;
                                }
                            }
                        }

                        span {
                            display: flex;
                            background-color: #1a1a1a;

                            .virtual-guitar-fret {
                                border-left: 5px solid grey;

                                &:first-child {
                                    border-left: 10px solid #b8b8b8;
                                }

                                &:last-child {
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }

                #virtual-guitar-aside {
                    display: flex;
                    width: max-content;
                    font-size: 1rem;

                    #virtual-guitar-settings-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 6.8rem;
                        height: 2rem;

                        #virtual-guitar-settings-icon {
                            border-width: 2px;
                            border-style: solid;
                            border-radius: 2rem;
                            background-color: black;
                            width: 2rem;
                            height: 2rem;
                            transition: 1s;

                            &:hover {
                                cursor: pointer;
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .virtual-guitar-fret-marker {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 6.8rem;
                        height: 2rem;
                        font-family: 'Inter';
                    }
                }

                #guitar-tuning-dialog-backdrop {
                    position: fixed;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background-color: #00000095;
                    z-index: 3;

                    #guitar-tuning-dialog {
                        padding: 1.5rem;
                        border-radius: 1rem;

                        #guitar-tuning-title {
                            font-family: 'Inter';
                        }

                        #guitar-tuning-strings {
                            width: 15rem;

                            .guitar-tuning-string {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .guitar-tuning-change-icon {
                                    padding: 1rem;
                                    font-size: 2rem;
                                }

                                .guitar-tuning-display {
                                    display: flex;
                                    flex-direction: column;

                                    .guitar-tuning-display-section {

                                        span {
                                            font-family: 'Inter';
                                        }
                                    }

                                }
                            }
                        }

                        #guitar-tuning-dialog-actions {

                            button {
                                padding: 0.5rem 1rem;

                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $tv-width) {

        #virtual-guitar-title {

            h2 {
                font-size: 2.8rem;
            }
        }

        #virtual-guitar-content {

            #virtual-guitar-control-panel {

                #virtual-guitar-main-panel {

                    .virtual-guitar-main-panel-item {

                        .main-panel-label {}

                        .main-panel-selection {

                            &:hover {}
                        }
                    }
                }

                #virtual-guitar-secondary-panel {

                    .virtual-guitar-secondary-panel-item {

                        .secondary-panel-label {}

                        #virtual-guitar-triads {

                            p {}

                            .triad-button {

                                &:hover {}

                                .triad-button-notes {}
                            }
                        }
                    }
                }
            }

            #virtual-guitar-fretboard-container {

                #virtual-guitar-neck-container {

                    #virtual-guitar-neck {

                        .virtual-guitar-string {

                            &:first-child {
                                .virtual-guitar-fret {}

                                span {

                                    .virtual-guitar-fret {}
                                }
                            }

                            &:last-child {
                                .virtual-guitar-fret {}

                                span {
                                    .virtual-guitar-fret {}
                                }
                            }

                            .virtual-guitar-fret {

                                &::after {}

                                .virtual-guitar-note {

                                    &:hover {}
                                }
                            }

                            span {

                                .virtual-guitar-fret {

                                    &:first-child {}

                                    &:last-child {}
                                }
                            }
                        }
                    }

                    #virtual-guitar-aside {

                        #virtual-guitar-settings-button {

                            #virtual-guitar-settings-icon {

                                &:hover {}
                            }
                        }

                        .virtual-guitar-fret-marker {}
                    }
                }
            }
        }
    }

    @media (max-width: $desktop-width) {
        #virtual-guitar-title {

            h2 {
                font-size: 2.6rem;
            }
        }

        #virtual-guitar-content {

            #virtual-guitar-control-panel {

                #virtual-guitar-main-panel {

                    .virtual-guitar-main-panel-item {

                        .main-panel-label {}

                        .main-panel-selection {

                            &:hover {}
                        }
                    }
                }

                #virtual-guitar-secondary-panel {

                    .virtual-guitar-secondary-panel-item {

                        .secondary-panel-label {}

                        #virtual-guitar-triads {

                            p {}

                            .triad-button {

                                &:hover {}

                                .triad-button-notes {}
                            }
                        }
                    }
                }
            }

            #virtual-guitar-fretboard-container {

                #virtual-guitar-neck-container {

                    #virtual-guitar-neck {

                        .virtual-guitar-string {

                            &:first-child {
                                .virtual-guitar-fret {}

                                span {

                                    .virtual-guitar-fret {}
                                }
                            }

                            &:last-child {
                                .virtual-guitar-fret {}

                                span {
                                    .virtual-guitar-fret {}
                                }
                            }

                            .virtual-guitar-fret {

                                &::after {}

                                .virtual-guitar-note {

                                    &:hover {}
                                }
                            }

                            span {

                                .virtual-guitar-fret {

                                    &:first-child {}

                                    &:last-child {}
                                }
                            }
                        }
                    }

                    #virtual-guitar-aside {

                        #virtual-guitar-settings-button {

                            #virtual-guitar-settings-icon {

                                &:hover {}
                            }
                        }

                        .virtual-guitar-fret-marker {}
                    }
                }
            }
        }
    }

    @media (max-width: $laptop-width) {
        #virtual-guitar-title {

            h2 {
                font-size: 2.3rem;
            }
        }

        #virtual-guitar-content {

            #virtual-guitar-control-panel {

                #virtual-guitar-main-panel {
                    grid-template-rows: 1fr;
                    grid-template-columns: 1fr;
                    row-gap: 1rem;

                    .virtual-guitar-main-panel-item {

                        .main-panel-label {}

                        .main-panel-selection {}
                    }
                }

                #virtual-guitar-secondary-panel {

                    .virtual-guitar-secondary-panel-item {

                        .secondary-panel-label {}

                        #virtual-guitar-triads {

                            p {}

                            .triad-button {

                                &:hover {}
                            }
                        }
                    }
                }
            }

            #virtual-guitar-fretboard-container {

                #virtual-guitar-neck-container {

                    #virtual-guitar-neck {

                        .virtual-guitar-string {

                            &:first-child {
                                .virtual-guitar-fret {}

                                span {

                                    .virtual-guitar-fret {}
                                }
                            }

                            &:last-child {
                                .virtual-guitar-fret {}

                                span {
                                    .virtual-guitar-fret {}
                                }
                            }

                            .virtual-guitar-fret {

                                &::after {}

                                .virtual-guitar-note {

                                    &:hover {}
                                }
                            }

                            span {

                                .virtual-guitar-fret {

                                    &:first-child {}

                                    &:last-child {}
                                }
                            }
                        }
                    }

                    #virtual-guitar-aside {

                        #virtual-guitar-settings-button {

                            #virtual-guitar-settings-icon {

                                &:hover {}
                            }
                        }

                        .virtual-guitar-fret-marker {}
                    }
                }
            }
        }
    }

    @media (max-width: $tablet-width) {

        #virtual-guitar-title {

            h2 {
                font-size: 2rem;
            }
        }

        #virtual-guitar-content {

            #virtual-guitar-control-panel {

                #virtual-guitar-main-panel {

                    .virtual-guitar-main-panel-item {

                        .main-panel-label {}

                        .main-panel-selection {}
                    }
                }

                #virtual-guitar-secondary-panel {

                    .virtual-guitar-secondary-panel-item {

                        .secondary-panel-label {}

                        #virtual-guitar-triads {

                            p {}

                            .triad-button {

                                &:hover {}
                            }
                        }
                    }
                }
            }

            #virtual-guitar-fretboard-container {
                width: auto;

                #virtual-guitar-neck-container {
                    flex-direction: row;
                    justify-content: center;
                    height: 35rem;
                    overflow-x: auto;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: 0.6rem;
                    }

                    &::-webkit-scrollbar-track {
                        border-radius: 1rem;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 1rem;
                    }

                    #virtual-guitar-neck {
                        padding: 0rem;
                        flex-direction: row-reverse;

                        .virtual-guitar-string {
                            flex-direction: column;
                            height: max-content;

                            &:first-child {
                                .virtual-guitar-fret {
                                    border: none;
                                    border-right: 3px solid transparent;
                                }

                                span {

                                    .virtual-guitar-fret {
                                        border: none;
                                        border-right: 3px solid #b8b8b8;
                                        border-top: 5px solid grey;

                                        &:first-child {
                                            border-right: 3px solid #b8b8b8;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                .virtual-guitar-fret {
                                    border: none;
                                    border-left: 3px solid transparent;
                                }

                                span {
                                    .virtual-guitar-fret {
                                        border: none;
                                        border-left: 3px solid #b8b8b8;
                                        border-top: 5px solid grey;

                                        &:first-child {
                                            border-left: 3px solid #b8b8b8;
                                        }
                                    }
                                }
                            }

                            .virtual-guitar-fret {
                                width: 2.9rem;
                                height: 6rem;

                                &::after {
                                    width: 2px;
                                    height: 130%;
                                }

                                .virtual-guitar-note {
                                    width: 2rem;
                                    height: 2rem;
                                    font-size: 0.8rem;

                                    &:hover {
                                        font-size: 0.8rem;
                                    }
                                }
                            }

                            span {
                                flex-direction: column;

                                .virtual-guitar-fret {
                                    border: none;
                                    border-top: 5px solid grey;

                                    &:first-child {
                                        border: none;
                                        border-top: 10px solid #b8b8b8;
                                    }
                                }
                            }
                        }
                    }

                    #virtual-guitar-aside {
                        flex-direction: column;
                        height: max-content;
                        font-size: 0.8rem;

                        #virtual-guitar-settings-button {
                            height: 6rem;
                            width: 2.9rem;

                            #virtual-guitar-settings-icon {}
                        }

                        .virtual-guitar-fret-marker {
                            height: 6rem;
                            width: 2.9rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: $mobile-width) {
        margin: 3rem 1rem;
        padding: 3rem 1rem;

        #virtual-guitar-title {

            h2 {
                font-size: 1.8rem;
            }
        }

        #virtual-guitar-content {

            #virtual-guitar-control-panel {
                margin: 2rem 0rem;

                #virtual-guitar-main-panel {

                    .virtual-guitar-main-panel-item {

                        .main-panel-label {
                            font-size: 0.8rem;
                        }

                        .main-panel-selection {
                            height: 2.5rem;
                            font-size: 0.9rem;
                        }
                    }
                }

                #virtual-guitar-secondary-panel {

                    .virtual-guitar-secondary-panel-item {
                        padding: 1rem;

                        .secondary-panel-label {}

                        #virtual-guitar-triads {

                            p {}

                            .triad-button {

                                &:hover {}
                            }
                        }
                    }
                }
            }

            #virtual-guitar-fretboard-container {

                #virtual-guitar-neck-container {

                    #virtual-guitar-neck {

                        .virtual-guitar-string {

                            &:first-child {
                                .virtual-guitar-fret {
                                    border-right: 3px solid transparent;
                                }

                                span {

                                    .virtual-guitar-fret {
                                        border-right: 3px solid #b8b8b8;
                                        border-top: 5px solid grey;

                                        &:first-child {
                                            border-right: 3px solid #b8b8b8;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                .virtual-guitar-fret {
                                    border-left: 3px solid transparent;
                                }

                                span {
                                    .virtual-guitar-fret {
                                        border-left: 3px solid #b8b8b8;
                                        border-top: 5px solid grey;

                                        &:first-child {
                                            border-left: 3px solid #b8b8b8;
                                        }
                                    }
                                }
                            }

                            .virtual-guitar-fret {
                                width: 2.5rem;
                                height: 5rem;

                                &::after {}

                                .virtual-guitar-note {
                                    width: 1.8rem;
                                    height: 1.8rem;
                                    font-size: 0.7rem;

                                    &:hover {
                                        font-size: 0.7rem;
                                        border-width: 5px;
                                    }
                                }
                            }

                            span {

                                .virtual-guitar-fret {
                                    border-top: 5px solid grey;

                                    &:first-child {
                                        border-top: 10px solid #b8b8b8;
                                    }
                                }
                            }
                        }
                    }

                    #virtual-guitar-aside {
                        font-size: 0.7rem;

                        #virtual-guitar-settings-button {
                            width: 2.5rem;
                            height: 5rem;

                            #virtual-guitar-settings-icon {
                                width: 1.8rem;
                                height: 1.8rem;
                            }
                        }

                        .virtual-guitar-fret-marker {
                            width: 2.5rem;
                            height: 5rem;
                        }
                    }
                }
            }
        }
    }
}