* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  caret-color: transparent;
}

html {
  scroll-behavior: smooth;
}
html body {
  background-color: #fff;
}
html body #app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  font-family: "Comfortaa", sans-serif, Arial;
  line-height: 1.5;
}

h1 {
  font-family: "Inter";
}

p {
  font-size: 1.25rem;
  line-height: 1.75;
}
@media (max-width: 1536px) {
  p {
    font-size: 1.15rem;
  }
}
@media (max-width: 1200px) {
  p {
    font-size: 1.05rem;
  }
}
@media (max-width: 1024px) {
  p {
    font-size: 0.95rem;
  }
}
@media (max-width: 768px) {
  p {
    font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  p {
    font-size: 0.8rem;
  }
}

.main-section-container {
  margin-left: auto;
  margin-right: auto;
}
.main-section-container .main-section {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1536px) {
  .main-section-container .main-section {
    max-width: 1200px;
  }
}
@media (max-width: 1200px) {
  .main-section-container .main-section {
    max-width: 1024px;
  }
}
@media (max-width: 1024px) {
  .main-section-container .main-section {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .main-section-container .main-section {
    max-width: 480px;
  }
}
@media (max-width: 480px) {
  .main-section-container .main-section {
    max-width: 100%;
  }
}

#navigation {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
}
#navigation #navigation-container {
  margin: 0rem 1.5rem;
  display: flex;
  padding: 0.5rem 0rem;
}
#navigation #navigation-container #nav-logo {
  flex-grow: 1;
  width: 0rem;
  display: flex;
  align-items: center;
}
#navigation #navigation-container #nav-logo #nav-logo-button {
  text-decoration: none;
}
#navigation #navigation-container #nav-logo #nav-logo-button h1 {
  font-family: "Inter";
  font-size: 1.8rem;
  font-weight: 800;
}
#navigation #navigation-container #nav-logo #nav-logo-button h1:hover {
  cursor: pointer;
}
#navigation #navigation-container #nav-logo #nav-logo-button h1 #nav-logo-nova {
  font-family: "Inter";
}
#navigation #navigation-container #nav-options {
  flex-grow: 1;
  width: 0rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#navigation #navigation-container #nav-options ul {
  display: flex;
  list-style: none;
}
#navigation #navigation-container #nav-options ul li {
  display: flex;
  justify-content: center;
  align-items: center;
}
#navigation #navigation-container #nav-options ul li .nav-option-button {
  margin: 0rem 1rem;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  transition: 0.2s;
}
#navigation #navigation-container #nav-options ul li .nav-option-button:hover {
  cursor: pointer;
}
#navigation #navigation-container #mobile-nav-options {
  flex-grow: 0;
  width: 0rem;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#navigation #navigation-container #mobile-nav-options #mobile-menu-button {
  padding: 0.3rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 1rem;
  transition: 0.5s;
}
#navigation #navigation-container #mobile-nav-options #mobile-menu-button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.3137254902);
}
#navigation #navigation-container #mobile-nav-options #mobile-menu-button .mobile-menu-bar {
  height: 1px;
  width: 100%;
  background-color: white;
  border-radius: 1px;
}
#navigation #navigation-container #mobile-nav-options #mobile-menu-container {
  position: absolute;
  border-radius: 1rem;
}
#navigation #navigation-container #mobile-nav-options #mobile-menu-container #mobile-menu {
  padding: 0.5rem;
  border-radius: 0.5rem;
}
#navigation #navigation-container #mobile-nav-options #mobile-menu-container #mobile-menu ul li {
  list-style: none;
  display: flex;
  justify-content: center;
}
#navigation #navigation-container #mobile-nav-options #mobile-menu-container #mobile-menu ul li a {
  padding: 0.5rem 1rem;
  width: 100%;
  text-decoration: none;
}
#navigation #navigation-container #mobile-nav-options .mobile-menu-active {
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 6.5rem);
  transition: 0.5s;
}
#navigation #navigation-container #mobile-nav-options .mobile-menu-inactive {
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 5.5rem);
  transition: 0.5s;
}
#navigation #navigation-container .nav-theme-button {
  padding: 0.5rem;
  display: flex;
  border-radius: 50%;
  transition: 0.5s;
}
#navigation #navigation-container .nav-theme-button:hover {
  cursor: pointer;
}
#navigation #navigation-container .nav-theme-button .theme-icon {
  font-size: 1.2rem;
}
@media (max-width: 1536px) {
  #navigation #navigation-container #nav-logo #nav-logo-button h1 {
    font-size: 1.8rem;
  }
}
@media (max-width: 1200px) {
  #navigation #navigation-container #nav-logo #nav-logo-button h1 {
    font-size: 1.7rem;
  }
  #navigation #navigation-container #nav-options ul li .nav-option-button {
    margin: 0rem 0.8rem;
    padding: 0.5rem 1.5rem;
    font-size: 0.9rem;
  }
  #navigation #navigation-container .nav-theme-button {
    padding: 0.4rem;
  }
  #navigation #navigation-container .nav-theme-button .theme-icon {
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  #navigation #navigation-container #nav-logo #nav-logo-button h1 {
    font-size: 1.5rem;
  }
  #navigation #navigation-container #nav-options ul li .nav-option-button {
    margin: 0rem 0.5rem;
    padding: 0.5rem 1.3rem;
    font-size: 0.8rem;
  }
  #navigation #navigation-container .nav-theme-button {
    padding: 0.3rem;
  }
  #navigation #navigation-container .nav-theme-button .theme-icon {
    font-size: 0.8rem;
  }
}
@media (max-width: 768px) {
  #navigation #navigation-container #nav-logo #nav-logo-button h1 {
    font-size: 1.3rem;
  }
  #navigation #navigation-container #nav-options {
    flex-grow: 0;
    overflow: hidden;
  }
  #navigation #navigation-container #mobile-nav-options {
    flex-grow: 1;
    overflow: auto;
  }
  #navigation #navigation-container #mobile-nav-options .mobile-menu-active {
    opacity: 1;
    visibility: visible;
  }
  #navigation #navigation-container #mobile-nav-options .mobile-menu-inactive {
    opacity: 0;
    visibility: hidden;
  }
  #navigation #navigation-container .nav-theme-button {
    padding: 0.5rem;
    width: 100%;
    justify-content: center;
    border-radius: 0.3rem;
  }
  #navigation #navigation-container .nav-theme-button .theme-icon {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  #navigation #navigation-container #nav-logo #nav-logo-button h1 {
    font-size: 1.1rem;
  }
}

#footer {
  width: 100%;
}
#footer #footer-bottom {
  padding: 1rem 0rem;
  font-size: 1rem;
  text-align: center;
}
@media (max-width: 1536px) {
  #footer #footer-bottom {
    font-size: 0.95rem;
  }
}
@media (max-width: 1200px) {
  #footer #footer-bottom {
    font-size: 0.9rem;
  }
}
@media (max-width: 1024px) {
  #footer #footer-bottom {
    font-size: 0.85rem;
  }
}
@media (max-width: 768px) {
  #footer #footer-bottom {
    font-size: 0.8rem;
  }
}
@media (max-width: 480px) {
  #footer #footer-bottom {
    font-size: 0.75rem;
  }
}

#virtual-guitar-container {
  margin: 5rem 2rem;
  padding: 5rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  -webkit-user-select: none;
}
#virtual-guitar-container #virtual-guitar-title {
  text-align: center;
}
#virtual-guitar-container #virtual-guitar-title h2 {
  font-size: 3rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel {
  margin: 3rem 0rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  border-radius: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel {
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  border-width: 2px;
  border-style: solid;
  border-radius: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel .virtual-guitar-main-panel-item {
  display: flex;
  flex-direction: column;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel .virtual-guitar-main-panel-item .main-panel-label {
  font-size: 0.9rem;
  font-weight: 800;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel .virtual-guitar-main-panel-item .main-panel-selection {
  height: 3rem;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.5rem;
  text-align: center;
  font-size: 1rem;
  background-color: transparent;
  appearance: none;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel .virtual-guitar-main-panel-item .main-panel-selection:hover {
  cursor: pointer;
  border-width: 3px;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel {
  display: grid;
  row-gap: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item {
  padding: 1rem;
  border-width: 2px;
  border-style: solid;
  border-radius: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item #virtual-guitar-triads {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item #virtual-guitar-triads p {
  font-size: 0.9rem;
  font-style: italic;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item #virtual-guitar-triads .triad-button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 5rem;
  padding: 1rem;
  font-size: 0.8rem;
  border-width: 2px;
  border-style: solid;
  border-radius: 0.5rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item #virtual-guitar-triads .triad-button:hover {
  cursor: pointer;
  border-width: 3px;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item #virtual-guitar-triads .triad-button .triad-button-notes {
  display: flex;
  flex-direction: column;
  text-align: center;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item #virtual-guitar-triads .triad-button .triad-button-notes span:nth-child(1) {
  font-weight: 900;
  font-family: "Inter";
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item #virtual-guitar-chord-constructor p {
  font-size: 0.9rem;
  font-style: italic;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #secondary-panel-button {
  display: flex;
  justify-content: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 1rem;
  font-size: 2rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #secondary-panel-button:hover {
  cursor: pointer;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5843137255);
  z-index: 3;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog {
  padding: 2rem;
  display: grid;
  gap: 1rem;
  border-radius: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog #add-secondary-panel-title {
  font-family: "Inter";
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog .add-secondary-panel-option {
  display: flex;
  align-items: center;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog .add-secondary-panel-option input {
  width: 1rem;
  height: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog .add-secondary-panel-option input:hover {
  cursor: pointer;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog .add-secondary-panel-option label {
  width: 100%;
  padding: 0.2rem 0.5rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog .add-secondary-panel-option label:hover {
  cursor: pointer;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog #add-secondary-panel-dialog-actions {
  display: flex;
  justify-content: center;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog #add-secondary-panel-dialog-actions button {
  padding: 0.5rem 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel #add-secondary-panel-dialog-backdrop #add-secondary-panel-dialog #add-secondary-panel-dialog-actions button:hover {
  cursor: pointer;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container {
  box-sizing: border-box;
  text-align: center;
  width: 100%;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container::-webkit-scrollbar {
  height: 0.6rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container::-webkit-scrollbar-track {
  border-radius: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container::-webkit-scrollbar-thumb {
  border-radius: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck {
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string {
  display: flex;
  width: max-content;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child .virtual-guitar-fret {
  border-top: 3px solid transparent;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child span .virtual-guitar-fret {
  border-top: 3px solid #b8b8b8;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child .virtual-guitar-fret {
  border-bottom: 3px solid transparent;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child span .virtual-guitar-fret {
  border-bottom: 3px solid #b8b8b8;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret {
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 3.4rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret::after {
  position: absolute;
  width: 130%;
  height: 2px;
  background-color: #9C7C2A;
  border-radius: 1rem;
  content: "";
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret .virtual-guitar-note {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  font-family: "Inter";
  font-size: 1rem;
  font-weight: 800;
  width: 2.2rem;
  height: 2.2rem;
  border-style: solid;
  border-radius: 50%;
  z-index: 1;
  transition: 0.1s;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret .virtual-guitar-note:hover {
  cursor: pointer;
  font-size: 1.5rem;
  border-width: 5px;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span {
  display: flex;
  background-color: #1a1a1a;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span .virtual-guitar-fret {
  border-left: 5px solid grey;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span .virtual-guitar-fret:first-child {
  border-left: 10px solid #b8b8b8;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span .virtual-guitar-fret:last-child {
  overflow: hidden;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside {
  display: flex;
  width: max-content;
  font-size: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside #virtual-guitar-settings-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 2rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside #virtual-guitar-settings-button #virtual-guitar-settings-icon {
  border-width: 2px;
  border-style: solid;
  border-radius: 2rem;
  background-color: black;
  width: 2rem;
  height: 2rem;
  transition: 1s;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside #virtual-guitar-settings-button #virtual-guitar-settings-icon:hover {
  cursor: pointer;
  transform: rotate(180deg);
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside .virtual-guitar-fret-marker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.8rem;
  height: 2rem;
  font-family: "Inter";
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5843137255);
  z-index: 3;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog {
  padding: 1.5rem;
  border-radius: 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-title {
  font-family: "Inter";
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-strings {
  width: 15rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-strings .guitar-tuning-string {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-strings .guitar-tuning-string .guitar-tuning-change-icon {
  padding: 1rem;
  font-size: 2rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-strings .guitar-tuning-string .guitar-tuning-display {
  display: flex;
  flex-direction: column;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-strings .guitar-tuning-string .guitar-tuning-display .guitar-tuning-display-section span {
  font-family: "Inter";
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-dialog-actions button {
  padding: 0.5rem 1rem;
}
#virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #guitar-tuning-dialog-backdrop #guitar-tuning-dialog #guitar-tuning-dialog-actions button:hover {
  cursor: pointer;
}
@media (max-width: 1536px) {
  #virtual-guitar-container #virtual-guitar-title h2 {
    font-size: 2.8rem;
  }
}
@media (max-width: 1200px) {
  #virtual-guitar-container #virtual-guitar-title h2 {
    font-size: 2.6rem;
  }
}
@media (max-width: 1024px) {
  #virtual-guitar-container #virtual-guitar-title h2 {
    font-size: 2.3rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}
@media (max-width: 768px) {
  #virtual-guitar-container #virtual-guitar-title h2 {
    font-size: 2rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container {
    width: auto;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container {
    flex-direction: row;
    justify-content: center;
    height: 35rem;
    overflow-x: auto;
    overflow-y: scroll;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container::-webkit-scrollbar {
    width: 0.6rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container::-webkit-scrollbar-track {
    border-radius: 1rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container::-webkit-scrollbar-thumb {
    border-radius: 1rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck {
    padding: 0rem;
    flex-direction: row-reverse;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string {
    flex-direction: column;
    height: max-content;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child .virtual-guitar-fret {
    border: none;
    border-right: 3px solid transparent;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child span .virtual-guitar-fret {
    border: none;
    border-right: 3px solid #b8b8b8;
    border-top: 5px solid grey;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child span .virtual-guitar-fret:first-child {
    border-right: 3px solid #b8b8b8;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child .virtual-guitar-fret {
    border: none;
    border-left: 3px solid transparent;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child span .virtual-guitar-fret {
    border: none;
    border-left: 3px solid #b8b8b8;
    border-top: 5px solid grey;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child span .virtual-guitar-fret:first-child {
    border-left: 3px solid #b8b8b8;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret {
    width: 2.9rem;
    height: 6rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret::after {
    width: 2px;
    height: 130%;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret .virtual-guitar-note {
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret .virtual-guitar-note:hover {
    font-size: 0.8rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span {
    flex-direction: column;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span .virtual-guitar-fret {
    border: none;
    border-top: 5px solid grey;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span .virtual-guitar-fret:first-child {
    border: none;
    border-top: 10px solid #b8b8b8;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside {
    flex-direction: column;
    height: max-content;
    font-size: 0.8rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside #virtual-guitar-settings-button {
    height: 6rem;
    width: 2.9rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside .virtual-guitar-fret-marker {
    height: 6rem;
    width: 2.9rem;
  }
}
@media (max-width: 480px) {
  #virtual-guitar-container {
    margin: 3rem 1rem;
    padding: 3rem 1rem;
  }
  #virtual-guitar-container #virtual-guitar-title h2 {
    font-size: 1.8rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel {
    margin: 2rem 0rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel .virtual-guitar-main-panel-item .main-panel-label {
    font-size: 0.8rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-main-panel .virtual-guitar-main-panel-item .main-panel-selection {
    height: 2.5rem;
    font-size: 0.9rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-control-panel #virtual-guitar-secondary-panel .virtual-guitar-secondary-panel-item {
    padding: 1rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child .virtual-guitar-fret {
    border-right: 3px solid transparent;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child span .virtual-guitar-fret {
    border-right: 3px solid #b8b8b8;
    border-top: 5px solid grey;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:first-child span .virtual-guitar-fret:first-child {
    border-right: 3px solid #b8b8b8;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child .virtual-guitar-fret {
    border-left: 3px solid transparent;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child span .virtual-guitar-fret {
    border-left: 3px solid #b8b8b8;
    border-top: 5px solid grey;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string:last-child span .virtual-guitar-fret:first-child {
    border-left: 3px solid #b8b8b8;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret {
    width: 2.5rem;
    height: 5rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret .virtual-guitar-note {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 0.7rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string .virtual-guitar-fret .virtual-guitar-note:hover {
    font-size: 0.7rem;
    border-width: 5px;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span .virtual-guitar-fret {
    border-top: 5px solid grey;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-neck .virtual-guitar-string span .virtual-guitar-fret:first-child {
    border-top: 10px solid #b8b8b8;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside {
    font-size: 0.7rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside #virtual-guitar-settings-button {
    width: 2.5rem;
    height: 5rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside #virtual-guitar-settings-button #virtual-guitar-settings-icon {
    width: 1.8rem;
    height: 1.8rem;
  }
  #virtual-guitar-container #virtual-guitar-content #virtual-guitar-fretboard-container #virtual-guitar-neck-container #virtual-guitar-aside .virtual-guitar-fret-marker {
    width: 2.5rem;
    height: 5rem;
  }
}

#home {
  flex-grow: 1;
}
#home #hero-section-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url("/public/hero_section_image.jpg");
  background-size: cover;
  background-position: center 80%;
  background-repeat: no-repeat;
}
#home #hero-section-container::before {
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(25%);
  content: "";
}
#home #hero-section-container #hero-section {
  position: relative;
  z-index: 1;
}
#home #hero-section-container #hero-section #hero-section-title {
  text-align: center;
  font-size: 5.5rem;
}
#home #hero-section-container #hero-section #hero-section-title span {
  display: block;
}
#home #hero-section-container #hero-section #hero-section-info {
  margin: 3rem auto;
  display: flex;
  width: 33rem;
}
#home #hero-section-container #hero-section #hero-section-info span {
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
}
#home #hero-section-container #hero-section #call-to-action {
  display: flex;
  justify-content: center;
}
#home #hero-section-container #hero-section #call-to-action #call-to-action-button {
  padding: 1.5rem 4rem;
  border-width: 5px;
  border-style: solid;
  font-size: 1.5rem;
  text-decoration: none;
  transition: 0.5s;
}
#home #hero-section-container #hero-section #call-to-action #call-to-action-button:hover {
  cursor: pointer;
}
@media (max-width: 1536px) {
  #home #hero-section-container #hero-section #hero-section-title {
    font-size: 4.5rem;
  }
  #home #hero-section-container #hero-section #hero-section-info {
    margin: 2.5rem auto;
    width: 27rem;
  }
  #home #hero-section-container #hero-section #hero-section-info span {
    font-size: 1.2rem;
  }
  #home #hero-section-container #hero-section #call-to-action #call-to-action-button {
    padding: 1.2rem 3.3rem;
    border-width: 4.1px;
    font-size: 1.2rem;
  }
}
@media (max-width: 1200px) {
  #home #hero-section-container #hero-section #hero-section-title {
    font-size: 3.5rem;
  }
  #home #hero-section-container #hero-section #hero-section-info {
    margin: 1.9rem auto;
    width: 21rem;
  }
  #home #hero-section-container #hero-section #hero-section-info span {
    font-size: 1rem;
  }
  #home #hero-section-container #hero-section #call-to-action #call-to-action-button {
    padding: 1rem 2.6rem;
    border-width: 3.2px;
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  #home #hero-section-container #hero-section #hero-section-title {
    font-size: 3rem;
  }
  #home #hero-section-container #hero-section #hero-section-info {
    margin: 1.6rem auto;
    width: 18rem;
  }
  #home #hero-section-container #hero-section #hero-section-info span {
    font-size: 0.8rem;
  }
  #home #hero-section-container #hero-section #call-to-action #call-to-action-button {
    padding: 0.8rem 2.2rem;
    border-width: 2.7px;
    font-size: 0.8rem;
  }
}
@media (max-width: 768px) {
  #home #hero-section-container #hero-section #hero-section-title {
    font-size: 2.5rem;
  }
  #home #hero-section-container #hero-section #hero-section-info {
    margin: 1.2rem auto;
    width: 14rem;
  }
  #home #hero-section-container #hero-section #hero-section-info span {
    font-size: 0.6rem;
  }
  #home #hero-section-container #hero-section #call-to-action #call-to-action-button {
    padding: 0.6rem 1.6rem;
    border-width: 2px;
    font-size: 0.6rem;
  }
}
#exercises {
  flex-grow: 1;
}
#exercises .page-title {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: "Inter";
}
#exercises .page-title a {
  white-space: nowrap;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: 0.2s;
}
#exercises .page-title a:hover {
  opacity: 0.2;
}
#exercises #exercise-content {
  margin: 3rem 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#exercises #exercise-content #exercise-content-prompt {
  text-align: center;
}
#exercises #exercise-content #exercise-categories {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
#exercises #exercise-content #exercise-categories .exercise-category {
  box-sizing: border-box;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 30rem;
  font-size: 2rem;
  border-radius: 1rem;
  transition: 0.5s;
}
#exercises #exercise-content #exercise-categories .exercise-category span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
#exercises #exercise-content #exercise-categories .exercise-category:hover {
  cursor: pointer;
  padding: 1rem;
  font-size: 2.5rem;
}
#exercises #exercise-content #exercise-categories .exercise-category:hover span {
  border: 2px solid white;
}
#exercises #exercise-content #category-exercises {
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#exercises #exercise-content #category-exercises .category-exercise-button {
  margin: 0rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 15rem;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 0.5rem;
  transition: 0.5s;
}
#exercises #exercise-content #category-exercises .category-exercise-button:hover {
  cursor: pointer;
  margin: 0rem;
  width: 18rem;
  height: 18rem;
}
#exercises #exercise-content #category-exercises .category-exercise-button .exercise-name {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1200px) {
  #exercises #exercise-content #exercise-categories .exercise-category {
    width: 25rem;
    height: 25rem;
    font-size: 1.5rem;
  }
  #exercises #exercise-content #exercise-categories .exercise-category:hover {
    padding: 0.8rem;
    font-size: 2rem;
  }
}
@media (max-width: 1024px) {
  #exercises #exercise-content #exercise-categories .exercise-category {
    width: 20rem;
    height: 20rem;
    font-size: 1.2rem;
  }
  #exercises #exercise-content #exercise-categories .exercise-category:hover {
    width: 20rem;
    height: 20rem;
    font-size: 1.6rem;
  }
  #exercises #exercise-content #category-exercises .category-exercise-button {
    width: 10rem;
    height: 10rem;
    font-size: 1rem;
  }
  #exercises #exercise-content #category-exercises .category-exercise-button:hover {
    width: 13rem;
    height: 13rem;
  }
}
@media (max-width: 768px) {
  #exercises .page-title {
    font-size: 2.5rem;
  }
  #exercises #exercise-content #exercise-categories {
    flex-direction: column;
    align-items: center;
  }
  #exercises #exercise-content #exercise-categories .exercise-category {
    width: 20rem;
    height: 15rem;
    font-size: 1.2rem;
  }
  #exercises #exercise-content #exercise-categories .exercise-category:hover {
    width: 20rem;
    height: 15rem;
    font-size: 1.5rem;
  }
  #exercises #exercise-content #category-exercises {
    flex-direction: column;
    height: 100%;
  }
  #exercises #exercise-content #category-exercises .category-exercise-button {
    margin: 1rem 0rem;
    width: 15rem;
    height: 8rem;
  }
  #exercises #exercise-content #category-exercises .category-exercise-button:hover {
    margin: 0rem;
    width: 15rem;
    height: 10rem;
  }
}
@media (max-width: 480px) {
  #exercises #exercise-content #exercise-categories .exercise-category {
    width: 20rem;
    height: 10rem;
    font-size: 1rem;
  }
  #exercises #exercise-content #exercise-categories .exercise-category:hover {
    width: 20rem;
    height: 10rem;
    font-size: 1.2rem;
  }
}

#exercise-content {
  -webkit-user-select: none;
}
#exercise-content #interval-range-selection-window {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
}
#exercise-content #interval-range-selection-window #interval-range-controls {
  display: flex;
  align-items: center;
}
#exercise-content #interval-range-selection-window #interval-range-controls #interval-range-display {
  display: flex;
  justify-content: center;
  width: 10rem;
  aspect-ratio: 1/1;
  font-size: 2em;
  animation-duration: 0.3s;
  animation-name: slideDown;
}
@keyframes slideDown {
  0% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}
#exercise-content #interval-range-selection-window #interval-range-controls .interval-range-control-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  height: 5rem;
  aspect-ratio: 1/1;
  border-radius: 0.5rem;
}
#exercise-content #interval-range-selection-window #interval-range-controls .interval-range-control-button:hover {
  cursor: pointer;
}
#exercise-content #interval-range-selection-window #interval-range-unit {
  margin-bottom: 3rem;
  font-size: 0.6em;
}
#exercise-content #interval-range-selection-window #interval-range-start-button {
  font-family: "Inter";
  font-size: 0.8em;
  border-width: 0.2rem;
  border-style: solid;
  border-radius: 0.5rem;
  padding: 1rem;
  transition: 0.3s;
}
#exercise-content #interval-range-selection-window #interval-range-start-button:hover {
  cursor: pointer;
}
#exercise-content #interval-training-window {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
#exercise-content #interval-training-window #note-interval-window {
  margin: 2rem 0rem;
  display: grid;
  grid-template: 8rem/repeat(2, 12rem);
  gap: 5rem;
}
#exercise-content #interval-training-window #note-interval-window .play-note-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
  border-style: solid;
  border-width: 0.2rem;
}
#exercise-content #interval-training-window #note-interval-window .play-note-button span {
  z-index: 2;
}
#exercise-content #interval-training-window #note-interval-window .play-note-button:hover {
  cursor: pointer;
}
#exercise-content #interval-training-window #note-interval-window .play-note-button .play-note-button-title {
  font-family: "Inter";
}
#exercise-content #interval-training-window #note-interval-window .play-note-button .play-note-button-value span {
  font-family: "Inter";
}
#exercise-content #interval-training-window #note-interval-window .play-note-button .play-note-button-overlay {
  position: absolute;
  font-size: 8em;
  transform: rotate(270deg);
}
#exercise-content #interval-training-window #half-step-window {
  font-weight: bold;
}
#exercise-content #interval-training-window #note-selection-window {
  display: grid;
  grid-template: repeat(3, 6rem)/18rem;
  gap: 1rem;
  font-size: 2rem;
}
#exercise-content #interval-training-window #note-selection-window span {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}
#exercise-content #interval-training-window #note-selection-window .note-change-button {
  margin: 1rem 3rem;
  border-width: 0.2rem;
  border-style: solid;
  transition: 0.3s;
}
#exercise-content #interval-training-window #note-selection-window .note-change-button:hover {
  cursor: pointer;
}
#exercise-content #interval-training-window #note-selection-window #note-select-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-size: 2em;
  border-width: 0.2rem;
  border-style: solid;
  animation-duration: 0.3s;
  animation-name: expand;
}
#exercise-content #interval-training-window #note-selection-window #note-select-button:hover {
  cursor: pointer;
}
@keyframes expand {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
#exercise-content #interval-training-window #octave-window {
  font-size: 1.5rem;
}
#exercise-content #interval-training-window #octave-window span {
  font-family: "Inter";
}
#exercise-content #interval-training-window #interval-training-result-window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #result-icon-badge {
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #result-icon-badge #result-icon {
  padding: 1rem;
  color: white;
  font-size: 6em;
  border-radius: 50%;
  animation-duration: 0.3s;
  animation-name: burst-out;
}
@keyframes burst-out {
  0% {
    transform: scale(0.2);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results {
  font-size: 0.6em;
  margin: 1rem 0rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-button:hover {
  cursor: pointer;
  font-weight: 900;
  text-decoration: underline;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5843137255);
  z-index: 3;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog {
  padding: 2rem;
  display: grid;
  gap: 2rem;
  border-radius: 1rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-results-title {
  font-size: 1.5rem;
  text-align: center;
  font-family: "Inter";
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-result-items {
  display: grid;
  gap: 0.5rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-result-items .detailed-result-item {
  display: flex;
  align-items: center;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-result-items .detailed-result-item .play-note-result-button {
  margin: 0rem 1rem;
  padding: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.2rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-result-items .detailed-result-item .play-note-result-button:hover {
  cursor: pointer;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-result-items .detailed-result-item .detailed-result-note span {
  font-family: "Inter";
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-results-dialog-actions {
  display: flex;
  justify-content: center;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-results-dialog-actions button {
  padding: 0.5rem 1rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog #detailed-results-dialog-actions button:hover {
  cursor: pointer;
}
#exercise-content #interval-training-window #interval-training-result-window #result-window-actions #next-question-button {
  padding: 1rem;
  font-family: "Inter";
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 0.2rem;
}
#exercise-content #interval-training-window #interval-training-result-window #result-window-actions #next-question-button:hover {
  cursor: pointer;
}
@media (max-width: 768px) {
  #exercise-content #interval-training-window #note-interval-window {
    font-size: 0.8rem;
    grid-template: 6rem/repeat(2, 10rem);
    gap: 2rem;
  }
  #exercise-content #interval-training-window #note-interval-window .play-note-button .play-note-button-overlay {
    font-size: 5em;
  }
}
@media (max-width: 480px) {
  #exercise-content #interval-training-window #note-interval-window {
    font-size: 0.8rem;
    grid-template: 5rem/repeat(2, 9rem);
    gap: 1.5rem;
  }
  #exercise-content #interval-training-window #interval-training-result-window #result-data-display #detailed-results #detailed-results-dialog-backdrop #detailed-results-dialog {
    padding: 1rem;
    font-size: 0.8rem;
  }
}

#about {
  flex-grow: 1;
}
#about .page-title {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 3rem;
  font-family: "Inter";
  text-align: center;
}
#about #about-content {
  padding: 1rem;
  font-size: 1.5rem;
}
#about #about-content p {
  font-size: 1em;
  line-height: 2em;
  text-align: center;
}
#about #about-content p:first-child {
  font-size: 1.5em;
}
#about #about-content p span {
  font-family: Inter;
}
@media (max-width: 1536px) {
  #about #about-content p {
    font-size: 0.9em;
  }
  #about #about-content p:first-child {
    font-size: 1.2em;
  }
}
@media (max-width: 1200px) {
  #about #about-content p {
    font-size: 0.8em;
  }
  #about #about-content p:first-child {
    font-size: 1.1em;
  }
}
@media (max-width: 1024px) {
  #about #about-content p {
    font-size: 0.7em;
  }
  #about #about-content p:first-child {
    font-size: 1em;
  }
}
@media (max-width: 768px) {
  #about #about-content p {
    font-size: 0.6em;
  }
  #about #about-content p:first-child {
    font-size: 0.8em;
  }
}
@media (max-width: 480px) {
  #about #about-content p {
    font-size: 0.5em;
  }
  #about #about-content p:first-child {
    font-size: 0.7em;
  }
}