.main-section-container {
    margin-left: auto;
    margin-right: auto;

    .main-section {
        max-width: $tv-width;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: $tv-width) {
            max-width: $desktop-width;
        }

        @media (max-width: $desktop-width) {
            max-width: $laptop-width;
        }

        @media (max-width: $laptop-width) {
            max-width: $tablet-width;
        }

        @media (max-width: $tablet-width) {
            max-width: $mobile-width;
        }

        @media (max-width: $mobile-width) {
            max-width: 100%;
        }
    }
}